import React, { useEffect } from "react";
import { Row, Col, Button, Badge } from "antd";
import Header from "../header/header.js";
import Footer from "./footer";
import "./Landing.css";
import "./footer.css";
import "slick-carousel/slick/slick.css";
import greenTick from "../asset/home/greenTick.svg";

import "slick-carousel/slick/slick-theme.css";
import SimpleSlider from "./slider";
import { withRouter } from "react-router-dom";
import styles from "./footer.module.css";

const Features = (props) => {
  useEffect(() => {
    scrollToTop();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <Row className="responsive-div">
      <Col>
        <Header />

        <Row className="home-outer-div">
          {/* <Row style={{ display: "flex", alignItems: "center" }}> */}
          <Row className="container">
            <Col xs={24} md={12}>
              <div className={`${styles.heading} ${styles.marginTp}`}>
                Growth More Efficient
              </div>
              <Col
                xs={24}
                className="flex"
                style={{
                  marginLeft: "18.5%",
                  width: "70%",
                  marginTop: "1%",
                }}
              >
                <span className="tick">
                  <img src={greenTick} />
                </span>
                <p className="marginL2 para2">
                  When it comes to developing new features for Sendbiz,our
                  philosophy is simple - it's all about growing your business.
                </p>
              </Col>
              <Col
                xs={24}
                className="flex"
                style={{
                  marginLeft: "18.5%",
                  width: "70%",
                  marginTop: "1%",
                }}
              >
                <span className="tick">
                  <img src={greenTick} />
                </span>
                <p className="marginL2 para2">
                  We are convinced that visibility & proximity will increase
                  sales of your product and services and will allow you to hire
                  next door.
                </p>
              </Col>{" "}
            </Col>
            <Col xs={24} md={12}>
              <img
                className="home-image"
                src={process.env.PUBLIC_URL + "features.gif"}
                alt="candadateFooter"
                // style={{
                //   maxWidth: "70%",
                //   height: "auto",
                //   marginLeft: "100px",
                // }}
              />
            </Col>
          </Row>
          {/* </Row> */}
          <Col xs={24} className={styles.headingCol}>
            <Col xs={24} className={styles.heading}>
              Features
            </Col>
          </Col>
          <Col xs={24} className={styles.sliderCol}>
            <SimpleSlider />
          </Col>
          <Row className={styles.container}>
            <Col className={styles.innerContainer}>
              <Row className={styles.title}>Follow up on every deal</Row>
              <hr className="follow-up-line" />
              <Row>
                <Col xs={20} className={styles.titleValue}>
                  Each time someone is interested in one of your products or
                  services or to respond to one of your mission/ advice
                  requests, you will receive a notification
                </Col>
              </Row>
              <Row style={{ marginTop: 50 }}>
                <Col xs={24} md={12} className={styles.container2}>
                  <Col className={styles.innerContainer2}>
                    <h3 className="inside-fblock">Product / Services</h3>
                    <Row style={{ marginTop: "30px" }}>
                      <Col span={1} align="center">
                        <Badge color="#D46161" style={{ paddingTop: "6px" }} />
                      </Col>
                      <Col span={23} className="para-fblock">
                        Insert the products / Services / Missions that you offer
                        in unlimited number.
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "20px" }}>
                      <Col span={1} align="center">
                        <Badge color="#D46161" style={{ paddingTop: "6px" }} />
                      </Col>
                      <Col span={23} className="para-fblock">
                        You are notified by email as soon as a person / company
                        is interested in one of your products / services.
                      </Col>
                    </Row>
                  </Col>
                </Col>
                <Col xs={24} md={12} className={styles.container2}>
                  <Col className={styles.innerContainer2}>
                    <h3 className="inside-fblock">Delivery</h3>
                    <Row style={{ marginTop: "30px" }}>
                      <Col span={1} align="center">
                        <Badge color="#D46161" style={{ paddingTop: "6px" }} />
                      </Col>
                      <Col span={23} className="para-fblock">
                        Look at the mission searches offered by other companies.{" "}
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "20px" }}>
                      <Col span={1} align="center">
                        <Badge color="#D46161" style={{ paddingTop: "6px" }} />
                      </Col>
                      <Col span={23} className="para-fblock">
                        Send / receive proposals from companies concerning
                        offers of assignments /advice and con tract directly
                        without our intermediation.
                      </Col>
                    </Row>
                  </Col>
                </Col>
              </Row>
            </Col>
            <Row className={styles.container1}>
              <Col className={styles.innerContainer}>
                <Row className={styles.title}>
                  Follow up on every application
                </Row>
                <hr className="follow-up-line" />
                <Row>
                  <Col xs={20} className={styles.titleValue}>
                    Whenever your company needs to find new collaborators, you
                    can freely post any type of job or mission offer. You can
                    follow and examine each application, contact them directly,
                    discuss with them via the mailbox
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} className={styles.innerContainer2}>
                    <h3 className="inside-fblock">Product / Services</h3>
                    <Row style={{ marginTop: "25px" }}>
                      <Col xs={1} align="center">
                        <Badge color="#D46161" style={{ paddingTop: "6px" }} />
                      </Col>
                      <Col xs={23} className="para-fblock">
                        Post all your job offers, missions, etc.
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "28px" }}>
                      <Col xs={1} align="center">
                        <Badge color="#D46161" style={{ paddingTop: "6px" }} />
                      </Col>
                      <Col xs={23} className="para-fblock">
                        View applications by offer.
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "28px" }}>
                      <Col xs={1} align="center">
                        <Badge color="#D46161" style={{ paddingTop: "6px" }} />
                      </Col>
                      <Col xs={23} className="para-fblock">
                        Manage candidate applications in a simple and efficient
                        way; and have your own career page.
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "28px" }}>
                      <Col xs={1} align="center">
                        <Badge color="#D46161" style={{ paddingTop: "6px" }} />
                      </Col>
                      <Col xs={23} className="para-fblock">
                        Get in touch with the applications that interest you
                        directly.
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className={styles.container1}>
              <Col className={styles.innerContainer}>
                <Row>
                  <Col xs={0} md={9}>
                    <img
                      className={styles.featureLady}
                      src={process.env.PUBLIC_URL + "FeaturesFooter2.gif"}
                      alt="features-women"
                    />
                  </Col>
                  <Col xs={24} md={0}>
                    <img
                      className={styles.image2}
                      style={{ objectFit: "contain" }}
                      src={process.env.PUBLIC_URL + "FeaturesFooter2.gif"}
                      alt="featuresFooter1"
                    />
                  </Col>
                  <Col xs={24} md={15} className={styles.innerContainer}>
                    <Col className={styles.title}>
                      Visibility for Customer & Candidate experience
                    </Col>
                    <Col
                      className={styles.titleValue}
                      style={{ paddingTop: 28 }}
                    >
                      You can enrich your company file, your job offers, your
                      products and services with beautiful medias in order to
                      highlight your skills, your values in order to attract
                      customers and the best candidates.
                    </Col>
                    <Row style={{ marginTop: "16px" }}>
                      <Col span={1} align="center">
                        <Badge color="#D46161" style={{ paddingTop: "6px" }} />
                      </Col>
                      <Col span={23} className="follow-up-para">
                        Add, modify as many medias as you want.
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "16px" }}>
                      <Col span={1} align="center">
                        <Badge color="#D46161" style={{ paddingTop: "6px" }} />
                      </Col>
                      <Col span={23} className="follow-up-para">
                        Enter your data, update them and find them on a single
                        screen.
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "16px" }}>
                      <Col span={1} align="center">
                        <Badge color="#D46161" style={{ paddingTop: "6px" }} />
                      </Col>
                      <Col span={23} className="follow-up-para">
                        Highlight your company profile on your sector of
                        activity to be seen during the searches.
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "16px" }}>
                      <Col span={1} align="center">
                        <Badge color="#D46161" style={{ paddingTop: "6px" }} />
                      </Col>
                      <Col span={23} className="follow-up-para">
                        Share your great company profile, job offers, products
                        and services on social networks easily to increase your
                        sales and applications.
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
          <Col xs={24} className={styles.container1}>
            <Button
              type="primary"
              size="large"
              className="feature-button-style"
              onClick={() => props.history.push("/login")}
            >
              Try Sendbiz Now
            </Button>
          </Col>
        </Row>
        <div className="fotter-modif">
          <Footer />
        </div>
      </Col>
    </Row>
  );
};
export default withRouter(Features);
