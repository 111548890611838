import React, { Component } from "react";
import "./newFooter.css";
import { NavLink } from "react-router-dom";
import { YoutubeOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import logo from "../asset/sendbiz_logo.png";
class Footer extends Component {
  render() {
    return (
      <>
        <Row className="footer-main-class">
          <Col xs={24} sm={9}>
            <Row className="footer-logo-div">
              <div>
                <img
                  src={logo}
                  width="120px"
                  height="50px"
                  className="d-inline-block"
                  alt="logo"
                />
              </div>
            </Row>
          </Col>
          <Col xs={24} sm={10}>
            <Row>
              <Col xs={10} sm={8} className="master-footer-col1">
                <Col xs={24} style={{ marginTop: "40px" }}>
                  <NavLink to={"/about-us"} style={{ padding: 0 }}>
                    <p className="first-footer-heading">ABOUT US</p>
                  </NavLink>
                </Col>
                <Col xs={24} style={{ padding: "2px" }}>
                  <a
                    href="https://www.sendbiz.com/companies"
                    style={{ padding: 0 }}
                  >
                    <span className="footer-heading-all">Companies</span>
                  </a>
                </Col>
                <Col xs={24} style={{ padding: "2px" }}>
                  <NavLink to={"/subcontractors"} style={{ padding: 0 }}>
                    <span className="footer-heading-all">Subcontractors</span>
                  </NavLink>
                </Col>
                <Col xs={24} style={{ padding: "2px" }}>
                  <NavLink to={"/all_jobs"} style={{ padding: 0 }}>
                    <span className="footer-heading-all">Jobs</span>
                  </NavLink>
                </Col>
                <Col xs={24} style={{ padding: "2px" }}>
                  <NavLink to={"/price"} style={{ padding: 0 }}>
                    <span className="footer-heading-all">Price</span>
                  </NavLink>
                </Col>
              </Col>
              <Col xs={10} sm={8} className="master-footer-col2">
                <Col xs={24} style={{ marginTop: "40px" }}>
                  <p className="first-footer-heading">PRODUCT</p>
                </Col>
                <Col xs={24} style={{ padding: "2px" }}>
                  <NavLink to={"/why_choose"} style={{ padding: 0 }}>
                    <span className="footer-heading-all">Why Choose us?</span>
                  </NavLink>
                </Col>
                <Col xs={24} style={{ padding: "2px" }}>
                  <NavLink to={"/features"} style={{ padding: 0 }}>
                    <span className="footer-heading-all">Features</span>
                  </NavLink>
                </Col>
                <Col xs={24} style={{ padding: "2px" }}>
                  <NavLink to={"/solutions"} style={{ padding: 0 }}>
                    <span className="footer-heading-all">Solutions</span>
                  </NavLink>
                </Col>
                <Col xs={24} style={{ padding: "2px" }}>
                  <NavLink to={"/candidate"} style={{ padding: 0 }}>
                    <span className="footer-heading-all">People/Candidate</span>
                  </NavLink>
                </Col>
              </Col>
              <Col xs={20} sm={8} className="master-footer-col3">
                <Col xs={24} className="marginMobile">
                  <p className="first-footer-heading">SUPPORT</p>
                </Col>
                <Col xs={24} style={{ padding: "2px" }}>
                  <a href="http://www.sendbiz.blog" target="_blank">
                    <p
                      className="footer-heading-all p-0"
                      style={{
                        padding: "-10px",
                        marginTop: "-1.5rem",
                        marginBottom: "-0.3rem",
                      }}
                    >
                      Blogs
                    </p>
                  </a>
                </Col>
                <Col xs={24} style={{ padding: "2px" }}>
                  <a href="https://sendbiz.helpscoutdocs.com/" target="_blank">
                    <p
                      className="footer-heading-all p-0"
                      style={{
                        padding: "-10px",
                        marginTop: "-1.5rem",
                        marginBottom: "-0.3rem",
                      }}
                    >
                      FAQ’s
                    </p>
                  </a>
                </Col>
                <Col xs={24} style={{ padding: "2px" }}>
                  <p className="footer-heading-all">
                    <a
                      href="https://www.youtube.com/@sendbiz"
                      style={{ color: "#ed5050" }}
                    >
                      <YoutubeOutlined
                        style={{
                          fontSize: "25px",
                          marginLeft: "-7px",
                          marginTop: "-15px",
                        }}
                      />
                    </a>
                  </p>
                </Col>
              </Col>
            </Row>
          </Col>
          <Row style={{ width: "100%" }} justify="center">
            {/* <div className="copyrightDiv"> */}
            <Col sm={24} xs={24}>
              <p className="copyRightAndTerms">
                Trademark by Sendbiz Inc 2024 -
                <NavLink to={"/terms-and-conditions"} style={{ padding: 0 }}>
                  <span className="footer-heading-all">
                    {" "}
                    Terms and Conditions -{" "}
                  </span>
                </NavLink>
                <NavLink to={"/privacy-policy"} style={{ padding: 0 }}>
                  <span className="footer-heading-all">Privacy Policy</span>
                </NavLink>
              </p>
            </Col>
          </Row>
        </Row>
      </>
    );
  }
}

export default Footer;
